<template>
  <div>
    <Header 
      button="Fale conosco"
      title="Dúvidas frequentes"
      subtitle="Confira nossa seção de dúvidas frequentes, para o caso de algum problema com algum de nossos produtos."
      @click="contact()"
    >
      <Select @input="getList()" name="categoria" label="Categoria" :values="categories" v-model="selected" class="mt-5" />
      <div class="mx-auto divide-y-2 divide-gray-200">
        <dl class="mt-6 space-y-6 divide-y divide-gray-200">
          <div v-for="(item, index) in list" :key="index">
            <dt class="pt-6">
              <button @click="item.expanded = !item.expanded" type="button" class="text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0" aria-expanded="false">
                <span class="font-medium text-gray-700">
                  {{ item.title }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <svg class="rotate-0 h-6 w-6 transform" :class="item.expanded ? '-rotate-180' : 'rotate-0'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </span>
              </button>
            </dt>
            <dd v-if="item.expanded" class="mt-2 pr-12" id="faq-0">
              <p class="text-base text-gray-500">{{ item.content }}</p>
            </dd>
          </div>
        </dl>
      </div>
    </Header>
  </div>
</template>

<script>
import { faqsColl, listingsColl } from '@/firebase'

export default {
  components: {
    Select: () => import('@/components/form/select'),
    Header: () => import('@/components/layout/header'),
  },

  data() {
    return {
      selected: '',
      expand: false,
      list: [],
      categories: [],
    }
  },

  mounted() { this.getCategories() },

  methods: {
    async getCategories() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Categories').get()
      .then((result) => { this.categories = result.data().available.filter(item => item.faqs > 0).map(item => item.name) })
      .catch(() => { this.$toast.error('Erro ao obter categorias, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async getList() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await faqsColl.doc(this.selected).get()
      .then((result) => { this.list = result.data().items.map(item => Object.assign(item, { expanded: false })) })
      .catch(() => { this.$toast.error('Erro ao obter lista, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    contact() {
      window.open('https://api.whatsapp.com/send?phone=5519999987126', '_blank')
    }
  }
}
</script>